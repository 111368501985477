@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  scroll-behavior: smooth;
}

.responsive-grid {
  @apply grid grid-cols-4 gap-3 tablet:grid-cols-6 tablet:gap-8 desktop:grid-cols-12 desktop:gap-6;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container {
  @apply tablet:px-6;
}

/* Flatfile iframe to enable vertical scrolling on all screen sizes.
It wasn't working on smaller screens. */

/* Need to use !important since the iframe uses inline styles */

.flatfile_iFrameContainer {
  height: 100% !important;
  max-height: 80dvh;
}

.flatfile_iframe-wrapper {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

// Braintree input field styling

.braintree-input {
  @apply flex h-12 w-full rounded border border-transparent bg-helsinki-blue-5 p-[14px] pr-7 text-sm leading-[20px] text-helsinki-blue-dark file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-80 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray/50;
}

.braintree-input.braintree-hosted-fields-focused {
  @apply border-helsinki-blue bg-white outline-none ring-1 ring-helsinki-blue;
}

.braintree-input.braintree-hosted-fields-invalid {
  @apply border-living-coral bg-living-coral-5;
}

.braintree-input.braintree-hosted-fields-valid {
  @apply border-helsinki-blue;
}

ol {
  counter-reset: item;
}

li {
  counter-increment: item;
}